<template>
  <div class='row'>
    <div class="col-12">
      <c-table
        ref="table"
        :editable="editable && !disabled"
        :title="$language('정산 실적')+' ['+vendor.vendorName+']'"
        tableId="monthlyPerformanceSMF"
        :columns="grid.columns"
        :data="vendor.itemResult"
        :gridHeight="gridHeight"
        :filtering="false"
        :columnSetting="false"
        :usePaging="false"
        selection="multiple"
        rowKey="smfMonthlyPerformanceItemResultId"
        @table-data-change="tableDataChange"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-if="editable && !disabled" :showLoading="false" label="추가" icon="add" @btnClicked="add" />
            <c-btn
              v-show="editable && !disabled && popupParam.smfMonthlyPerformanceId"
              :url="saveUrl"
              :isSubmit="isSave"
              :param="vendor"
              mappingType="PUT"
              label="저장"
              icon="save"
              @beforeAction="saveData"
              @btnCallback="saveCallback" />
            <c-btn v-if="editable && !disabled" label="제외" icon="remove" @btnClicked="remove" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'budget-result-info',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        smfMonthlyPerformanceId: '',
        plantCd: '',
        settlementMonth: '',
      }),
    },
    plant: {
      type: Object,
      default: () => ({
        plantCd: null,
        plantName: null,
        projectPeriod: null,
        projectSafetyCost: 0,
        stackAmount: 0,
        projectAddress: '',
        projectCost: 0,
        resultItems: [],
      }),
    },
    tabHeight: null,
  },
  data() {
    return {
      grid: {
        columns: [],
        data: [],
      },
      detailUrl: '',
      permitUrl: '',
      saveUrl: '',
      isSave: false,
      isPermit: false,
      editable: true,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
    underTabHeight() {
      return String(this.tabHeight - 20);
    },
    gridHeight() {
      return String(this.underTabHeight - 40) + 'px';
    },
    disabled() {
      return !this.permitCheck || this.vendor.completeFlag === 'Y'
    },
    start() {
      return this.$comm.moment(this.popupParam.settlementMonth).startOf('month').format('YYYY-MM-DD')
    },
    end() {
      return this.$comm.moment(this.popupParam.settlementMonth).endOf('month').format('YYYY-MM-DD')
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      if(this.$store.getters.user.innerFlag === 'N') {
        if (this.vendor.vendorCd === this.$store.getters.user.deptCd)  {
          this.editable = true;
        } else {
          this.editable = false;
        }
      } else {
        this.editable = this.$route.meta.editable;
      }
      // url setting
      this.detailUrl = selectConfig.smf.monthlyPerformanceVendor.get.url
      this.permitUrl = transactionConfig.smf.monthlyPerformanceVendor.permit.url
      this.saveUrl = transactionConfig.smf.monthlyPerformanceVendor.save.url

      this.$comm.getComboItems('SMF_ITEM_CD').then(_result => {
        this.grid.columns = [
          {
            name: 'smfItemCd',
            field: 'smfItemCd',
            label: '항목',
            align: 'center',
            style: 'width:320px',
            sortable: false,
            required: true,
            type: 'select',
            comboItems: _result
          },
          {
            name: 'settlementDate',
            field: 'settlementDate',
            label: '사용일',
            align: 'center',
            style: 'width:150px',
            sortable: false,
            required: true,
            type: 'date',
          },
          {
            name: 'detailItemName',
            field: 'detailItemName',
            label: '품목',
            align: 'left',
            style: 'width:220px',
            sortable: false,
            type: 'text'
          },
          {
            name: 'standard',
            field: 'standard',
            label: '규격',
            align: 'left',
            style: 'width:80px',
            sortable: false,
            type: 'text'
          },
          {
            name: 'unit',
            field: 'unit',
            label: '단위',
            align: 'left',
            style: 'width:70px',
            sortable: false,
            type: 'text'
          },
          {
            name: 'quantity',
            field: 'quantity',
            label: '수량',
            align: 'right',
            style: 'width:70px',
            sortable: false,
            required: true,
            type: 'number'
          },
          {
            name: 'unitPrice',
            field: 'unitPrice',
            label: '단가',
            align: 'right',
            style: 'width:130px',
            sortable: false,
            required: true,
            type: 'number'
          },
          {
            name: 'amount',
            field: 'amount',
            label: '금액',
            align: 'right',
            style: 'width:150px',
            sortable: false,
            required: true,
            type: 'number'
          },
          {
            name: 'vat',
            field: 'vat',
            label: '부가세',
            align: 'right',
            style: 'width:100px',
            sortable: false,
            type: 'number'
          },
          {
            name: 'attach1',
            field: 'attach1',
            label: '계산서',
            align: 'center',
            style: 'width:250px',
            type: 'attach',
            taskClassCd: 'SMF_BILL',
            keyText: 'smfMonthlyPerformanceItemResultId',
            sortable: false,
          },
          {
            name: 'attach2',
            field: 'attach2',
            label: '명세서',
            align: 'center',
            style: 'width:250px',
            type: 'attach',
            taskClassCd: 'SMF_SPECIFICATION',
            keyText: 'smfMonthlyPerformanceItemResultId',
            sortable: false,
          },
          // {
          //   name: 'attach3',
          //   field: 'attach3',
          //   label: '검수사진',
          //   align: 'center',
          //   style: 'width:130px',
          //   type: 'attach',
          //   taskClassCd: 'SMF_INSPECTION_PHOTO',
          //   keyText: 'smfMonthlyPerformanceItemResultId',
          //   sortable: false,
          // },
        ]
      });
    },
    getDetail() {
      this.$http.url = this.detailUrl
      this.$http.type = 'GET';
      this.$http.param = {
        smfMonthlyPerformanceVendorId: this.vendor.smfMonthlyPerformanceVendorId
      }
      this.$http.request((_result) => {
        this.$_.extend(this.vendor, _result.data)
      },);

    },
    tableDataChange(props, col) {
      if (col.name === 'quantity' || col.name === 'unitPrice') {
        if (props.row['quantity'] && props.row['unitPrice']) {
          props.row['amount'] = props.row['quantity'] * props.row['unitPrice']
        } else {
          props.row['amount'] = 0
        }
      }
    },
    add() {
      this.vendor.itemResult.push({
        smfMonthlyPerformanceItemResultId: uid(),  // 월별 안전보건관리비 항목별 사용실적 번호
        smfMonthlyPerformanceId: this.vendor.smfMonthlyPerformanceId,  // 월별 안전보건관리비 사용실적 번호
        smfMonthlyPerformanceVendorId: this.vendor.smfMonthlyPerformanceVendorId,  // 월별 안전보건관리비 업체 번호
        vendorCd: this.vendor.vendorCd,  // 업체코드
        smfItemCd: null,  // 항목 코드
        settlementDate: this.start,  // 사용일
        detailItemName: '',  // 품목
        standard: '',  // 규격
        unit: '',  // 단위
        quantity: 0,  // 수량
        unitPrice: 0,  // 단가
        amount: 0,  // 금액
        vat: 0,  // 부가세
        regUserId: this.$store.getters.user.userId,  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        editFlag: 'C',
      })
    },
    remove() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        if (!this.vendor.deleteItemResult) {
          this.vendor.deleteItemResult = [];
        }
        this.$_.forEach(selectData, item => {
          if (this.$_.findIndex(this.vendor.deleteItemResult, { 
            smfMonthlyPerformanceItemResultId: item.smfMonthlyPerformanceItemResultId 
          }) === -1 && item.editFlag !== 'C') {
            this.vendor.deleteItemResult.push(item);
          }
          this.vendor.itemResult = this.$_.reject(this.vendor.itemResult, item);
        })
        this.$refs['table'].$refs['compo-table'].clearSelection();
      }
    },
    saveData() {
      if (this.$comm.validTable(this.grid.columns, this.vendor.itemResult)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.vendor.regUserId = this.$store.getters.user.userId;
            this.vendor.chgUserId = this.$store.getters.user.userId;

            if (this.vendor.editFlag !== 'C') {
              this.vendor.editFlag = 'U'
            }

            this.isSave = !this.isSave;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.vendor.smfMonthlyPerformanceVendorId = result.data
      this.$emit('getDetail');
    },
    permitData() {
      if (this.$comm.validTable(this.grid.columns, this.vendor.itemResult)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '제출하시겠습니까?',
          
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.vendor.regUserId = this.$store.getters.user.userId;
            this.vendor.chgUserId = this.$store.getters.user.userId;

            if (this.vendor.editFlag !== 'C') {
              this.vendor.editFlag = 'U'
            }

            this.isPermit = !this.isPermit;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    permitCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.vendor.smfMonthlyPerformanceVendorId = result.data
      this.getDetail();
    },
    cuToThousandFilter(num) {
      let _num = (+num || 0).toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))
      return _num == 0 ? num : _num
    },
  }
};
</script>
